import { useList } from "~/providers/ListProvider";
import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import { type RepoEvaluation } from "@openqlabs/drm-db";
import WaitingForFirstEvaluation from "~/components/lists/MainPage/Cards/SharedCard/WaitingForFirstEvaluation";
import Developer from "~/components/lists/MainPage/Cards/RepoCard/tabs/Contributors/Developer";
import { api } from "~/utils/api";
export default function DevelopersTab() {
  const list = useList();
  const latestEvaluation = useLatestEvaluation<RepoEvaluation>();
  const {
    data: contact
  } = api.listContact.getContactByGithubName.useQuery({
    listId: list?.id,
    teamAccountId: latestEvaluation?.teamAccountId,
    githubName: latestEvaluation?.params?.login
  });
  if (!latestEvaluation) {
    return <WaitingForFirstEvaluation />;
  }
  return <div data-sentry-component="DevelopersTab" data-sentry-source-file="index.tsx">
      <div className="flex flex-col text-lg font-semibold text-black">
        The following contributors are working on this repository.
      </div>
      {list && <div className="py-3 text-lg">
          Add a Contributor to your list contacts to learn more about them. This
          will allow you to analyze each one and search for relevant activities.
        </div>}
      <div className="space-y-6 divide-y-2">
        {contact?.contributors?.map(author => <Developer key={author.login} mentionableUser={author} />)}
      </div>
    </div>;
}