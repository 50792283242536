import SecondaryInsightsDashboards from "./Dashboards";
const Conditions = ({
  userCount,
  leads,
  newLeads,
  customers
}: {
  userCount: number;
  leads: number;
  newLeads: number;
  customers: number;
}) => {
  return <div data-sentry-component="Conditions" data-sentry-source-file="Conditions.tsx">
      {userCount > 0 && <SecondaryInsightsDashboards userCount={userCount} leads={leads} newLeads={newLeads} customers={customers} />}
    </div>;
};
export default Conditions;