import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import Header from "../UsersTable/Header";
import Body from "../UsersTable/Body";
import TableLayout from "../SharedTable/TableLayout";
import { ultraLightColumns as columns } from "./Columns";
import type { UltraLightlistContact } from "@openqlabs/drm-db";
function UltraLightTable({
  userContactMap,
  handleGetNextUserPage,
  hasNextUserPage,
  isFetchingNextUsersPage,
  isLoadingUsers
}: Readonly<{
  userContactMap: UltraLightlistContact[] | null;
  handleGetNextUserPage: () => void;
  hasNextUserPage?: boolean;
  isFetchingNextUsersPage?: boolean;
  isLoadingUsers: boolean;
}>) {
  const toggleHook = useState(false);
  const [, setShowNewFieldModal] = toggleHook;
  const mappedData = useMemo(() => {
    return isLoadingUsers || !userContactMap ? [null, null, null, null, null, null, null, null, null, null, null, null] : userContactMap;
  }, [userContactMap, isLoadingUsers]);
  const table = useReactTable({
    data: mappedData,
    columns,
    getCoreRowModel: getCoreRowModel()
  });
  return <div data-sentry-component="UltraLightTable" data-sentry-source-file="index.tsx">
      <TableLayout toggleHook={toggleHook} type="user" data-sentry-element="TableLayout" data-sentry-source-file="index.tsx">
        <Header<UltraLightlistContact | null> table={table} setShowNewFieldModal={setShowNewFieldModal} hideFinalColumn={true} data-sentry-element="Header" data-sentry-source-file="index.tsx" />
        <Body<UltraLightlistContact | null> table={table} hasNextUserPage={hasNextUserPage} isFetchingNextUsersPage={isFetchingNextUsersPage} handleGetNextUserPage={handleGetNextUserPage} hideFinalColumn={true} hideFinalRow={true} data-sentry-element="Body" data-sentry-source-file="index.tsx" />
      </TableLayout>
    </div>;
}
export default UltraLightTable;