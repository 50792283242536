import Notification from "~/components/base/Notification";
import { useEffect, useState } from "react";
import { getDependenciesText } from "~/lib/utils";
import { useLatestDepsEvaluation } from "~/providers/DepsEvaluationProvider";
import { useTracker } from "~/providers/TrackerProvider";
import { type RepoDependenciesEvaluation } from "@openqlabs/drm-db";
import RepoDependenciesFoundPresentation from "../../../../../../shared/RepoDependenciesFoundPresentation";
import DependenciesLegend from "../../../../../../contact/shared/details/dependencies/DependenciesLegend";
import SelectTrackerSimple from "../../../../../../contact/shared/details/dependencies/SelectTrackerSimple";
import { isEnv } from "~/lib/isEnv";
export default function RepoContactDependencies() {
  const tracker = useTracker();
  const [isEvaluatingDeps, setIsEvaluatingDeps] = useState<boolean>(false);
  const [lastEvalError, setLastEvalError] = useState<string | null>(null);
  const lastEvalByTracker = useLatestDepsEvaluation<RepoDependenciesEvaluation>();
  useEffect(() => {
    if (lastEvalByTracker) {
      setIsEvaluatingDeps(lastEvalByTracker?.status === "waiting");
      setLastEvalError(lastEvalByTracker?.error);
    } else {
      setIsEvaluatingDeps(false);
    }
  }, [lastEvalByTracker]);
  const dependenciesSearched = tracker?.dependencies ?? [];
  const hasFoundDependencies = lastEvalByTracker?.result?.repoStatus != "inactive" && lastEvalByTracker?.result?.repoStatus != "cold";
  const finalText = getDependenciesText(hasFoundDependencies, dependenciesSearched, "repo");
  return <div className={`w-full space-y-3 px-8 pb-8`} data-sentry-component="RepoContactDependencies" data-sentry-source-file="RepoContactDependencies.tsx">
      <div className={`flex justify-between pt-8`}>
        <h2 className="flex items-center gap-1 text-lg font-bold">
          Dependencies status of repository{" "}
        </h2>
        <DependenciesLegend data-sentry-element="DependenciesLegend" data-sentry-source-file="RepoContactDependencies.tsx" />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 text-center text-gray-600">
          {!lastEvalByTracker && "No dependencies evaluated yet for this tracker."}
          {isEvaluatingDeps && "Searching for dependencies within the repository..."}
          {!isEvaluatingDeps && lastEvalByTracker && !lastEvalError && finalText}
        </div>
        {tracker && <div className="flex items-center gap-2">
            <div className="whitespace-nowrap">Analyzed via</div>
            <SelectTrackerSimple />
          </div>}
      </div>
      {!lastEvalByTracker && <div>Evaluating dependencies...</div>}
      {!isEvaluatingDeps && lastEvalByTracker && !lastEvalError && <RepoDependenciesFoundPresentation trackerName={tracker?.trackerName ?? ""} dependenciesSearched={dependenciesSearched} repoDepsEvaluation={lastEvalByTracker} />}
      {lastEvalError && (isEnv("development") ? <Notification id="RepoContactDependencies" title="Development Environment Error Only" className="my-4">
            The evaluation of dependencies failed. Please try again later.
          </Notification> : <div className="my-4 w-fit px-2 py-1 text-sm">
            Please run evaluation.
          </div>)}
    </div>;
}