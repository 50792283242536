import Notification from "~/components/base/Notification";
import { Constants } from "@openqlabs/utils";
export default function ListEvalProgress({
  waitingEvalsCount,
  doneEvalsCount
}: Readonly<{
  waitingEvalsCount: number;
  doneEvalsCount: number;
}>) {
  const previousResults = doneEvalsCount === 0;
  const percentageDone = doneEvalsCount / (doneEvalsCount + waitingEvalsCount);
  const considerDone = percentageDone > Constants.PERCENTAGE_CONSIDER_DONE;
  if (considerDone) return null;
  return <Notification id="ListEvalProgress" title={"Evaluation in Progress"} info={false} isLoading={true} data-sentry-element="Notification" data-sentry-component="ListEvalProgress" data-sentry-source-file="ListEvalProgress.tsx">
      The evaluation is still processing. Any insights showing below are
      preliminary and based on{" "}
      {previousResults ? "previous evaluations." : <>
          {doneEvalsCount} / {doneEvalsCount + waitingEvalsCount} evaluations
          performed.
        </>}{" "}
      {/*    */}
    </Notification>;
}