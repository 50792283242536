import { useContact } from "~/providers/ContactProvider";
import { GithubContactProvider } from "~/providers/GithubContactProvider";
import { ReactNode } from "react";
import { UserContact } from "@openqlabs/drm-db";
import { EvaluationProvider } from "~/providers/EvaluationProvider";
import { DepsEvaluationProvider } from "~/providers/DepsEvaluationProvider";
import useComprehensiveListContactInfo from "~/hooks/useComprehensiveListContactInfo";
const TabWrapper = ({
  children,
  type
}: {
  children: ReactNode | ReactNode[];
  type: "repo" | "user";
}) => {
  const {
    contact
  } = useContact();
  const contactWithChildren = useComprehensiveListContactInfo(type, contact);
  const githubEvaluation = contactWithChildren?.githubEvaluation;
  const githubDepsEvaluation = contactWithChildren?.githubDepsEvaluation;
  const dependenciesType = type === "repo" ? "repo-dependencies" : "user-dependencies";
  return <GithubContactProvider initialContact={((contact.githubContact as unknown) as UserContact)} data-sentry-element="GithubContactProvider" data-sentry-component="TabWrapper" data-sentry-source-file="TabWrapper.tsx">
      <EvaluationProvider evaluation={githubEvaluation} type="repo" data-sentry-element="EvaluationProvider" data-sentry-source-file="TabWrapper.tsx">
        <DepsEvaluationProvider depsEvaluation={githubDepsEvaluation} type={dependenciesType} data-sentry-element="DepsEvaluationProvider" data-sentry-source-file="TabWrapper.tsx">
          {children}
        </DepsEvaluationProvider>
      </EvaluationProvider>
    </GithubContactProvider>;
};
export default TabWrapper;