import { ArrowTopRightOnSquareIcon, FolderIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { useEffect, useState } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import RepoContactDependencies from "~/components/lists/MainPage/Cards/RepoCard/tabs/Dependencies/RepoContactDependencies";
import { getContributorRepos } from "~/lib/utils";
import { useList } from "~/providers/ListProvider";
import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import { useGithubContact } from "~/providers/GithubContactProvider";
import type { RepoContactMetadata, RepoEvaluation } from "@openqlabs/drm-db";
import { useTrackerName } from "~/providers/TrackerProvider";
import { useContact } from "~/providers/ContactProvider";
export default function DependenciesTab() {
  const {
    githubContact
  } = useGithubContact<RepoContactMetadata>();
  const {
    contact
  } = useContact();
  const trackerName = useTrackerName();
  const listId = useList()?.id;
  const [isEvaluatingRepo, setIsEvaluatingRepo] = useState<boolean>(false);
  const repoId = contact?.teamContactId ?? "";
  const latestRepoEvaluation = useLatestEvaluation<RepoEvaluation>();
  const contributorRepos = getContributorRepos(githubContact?.metadata?.nameWithOwner ?? "", latestRepoEvaluation);
  const randomRepoName = contributorRepos?.[0];
  useEffect(() => {
    if (latestRepoEvaluation && latestRepoEvaluation.status === "waiting") setIsEvaluatingRepo(true);else setIsEvaluatingRepo(false);
  }, [latestRepoEvaluation]);
  return <div className={`p-4 text-sm`} data-sentry-component="DependenciesTab" data-sentry-source-file="index.tsx">
      <RepoContactDependencies data-sentry-element="RepoContactDependencies" data-sentry-source-file="index.tsx" />

      {trackerName && <div className={`w-full space-y-3 border-t p-3 px-8 py-8`}>
          <h2 className="flex items-center gap-1 text-lg font-bold">
            Dependencies status of other repositories contributors work on{" "}
          </h2>
          <div className={`pb-4`}>
            Some repositories that contributors are involved with also may
            contain the tracked dependencies.
          </div>
          {!isEvaluatingRepo && !randomRepoName && <div className="text-gray-600">
              No repositories found. Have you evaluated the contact repository
              yet?
            </div>}
          {isEvaluatingRepo && !randomRepoName && <div className="flex items-center gap-2 text-center text-gray-600">
              Searching for contributor repositories... <LoadingSpinner />
            </div>}
          {randomRepoName && <div className="">
              <div className="relative h-32 overflow-y-scroll">
                <div className="border-t py-4 pt-8">
                  <h4 className="flex items-center gap-2 pb-2 text-lg">
                    <FolderIcon className="sm-icon" />
                    <Link href={`https://github.com/${randomRepoName ?? ""}`} target="_blank" className="font-bold text-blue-500 hover:cursor-pointer hover:underline flex items-center gap-1">
                      {randomRepoName}

                      <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
                    </Link>
                  </h4>
                  <div className="relative flex">
                    <div>
                      This repository may incorporate a portion of the tracked
                      dependencies - to find out more, click on the link below.
                    </div>
                  </div>
                </div>
              </div>

              <Link className="cursor-pointer text-blue-500 underline flex items-center gap-1" href={{
          pathname: `/contact/repo/${repoId}/status/${listId ?? ""}`
        }} target="_blank">
                {contributorRepos?.length} more repositories can be analyzed
                <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
              </Link>
            </div>}
        </div>}
    </div>;
}