import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useList } from "~/providers/ListProvider";
import { useCreateUserColumns } from "./Columns";
import Header from "./Header";
import Body from "./Body";
import TableLayout from "../SharedTable/TableLayout";
import { TeamRenderContact } from "~/providers/ContactProvider";
import { api } from "~/utils/api";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
function UsersTable({
  userContactMap,
  handleGetNextUserPage,
  hasNextUserPage,
  isFetchingNextUsersPage,
  isLoadingUsers,
  showEngagement
}: Readonly<{
  userContactMap: TeamRenderContact[] | null;
  handleGetNextUserPage: () => void;
  hasNextUserPage?: boolean;
  isFetchingNextUsersPage?: boolean;
  isLoadingUsers: boolean;
  showEngagement: boolean;
}>) {
  const list = useList();
  const {
    activeTeamAccount
  } = useTeamAccount();
  const toggleHook = useState(false);
  const [, setShowNewFieldModal] = toggleHook;
  const mappedData = useMemo(() => {
    return isLoadingUsers || !userContactMap ? [null, null, null, null, null, null, null, null, null, null, null, null] : userContactMap;
  }, [userContactMap, isLoadingUsers]);
  const customUserFields = list?.customFields.filter(field => field.contactType === "user");
  const {
    data: hasConnectedSocial
  } = api.listContact.listOrTeamHasConnectedSocials.useQuery({
    listId: list?.id,
    teamAccountId: activeTeamAccount?.id
  });
  const columns = useCreateUserColumns(customUserFields, showEngagement, hasConnectedSocial);
  const table = useReactTable({
    data: mappedData,
    columns,
    getCoreRowModel: getCoreRowModel()
  });
  return <div data-sentry-component="UsersTable" data-sentry-source-file="index.tsx">
      <TableLayout toggleHook={toggleHook} type="user" data-sentry-element="TableLayout" data-sentry-source-file="index.tsx">
        <Header<TeamRenderContact> table={table} setShowNewFieldModal={setShowNewFieldModal} hideFinalColumn={!list} data-sentry-element="Header" data-sentry-source-file="index.tsx" />
        <Body<TeamRenderContact> table={table} hasNextUserPage={hasNextUserPage} isFetchingNextUsersPage={isFetchingNextUsersPage} handleGetNextUserPage={handleGetNextUserPage} hideFinalColumn={!list} data-sentry-element="Body" data-sentry-source-file="index.tsx" />
      </TableLayout>
    </div>;
}
export default UsersTable;