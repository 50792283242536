/**
 * This file is (currently) an exception to the rule of one component per file.
 * It defines the layout of the Contact Details page.
 * The components only define structure and styles and render child components.
 */
import { type ReactNode } from "react";
export default function Card({
  children,
  className
}: Readonly<{
  children?: ReactNode | ReactNode[];
  className?: string;
}>) {
  return <div className={`mb-auto rounded-lg border border-divider bg-white dark:bg-gray-800 ${className ?? ""}`} data-sentry-component="Card" data-sentry-source-file="Card.tsx">
      {children}
    </div>;
}
export function Header({
  children
}: Readonly<{
  children?: ReactNode | ReactNode[];
}>) {
  return <div className="flex items-center justify-between border-b border-divider bg-offwhite px-3 py-2 font-bold dark:bg-gray-900/50" data-sentry-component="Header" data-sentry-source-file="Card.tsx">
      {children}
    </div>;
}
export function Body({
  children
}: Readonly<{
  children?: ReactNode | ReactNode[];
}>) {
  return <div className="gap-18 flex flex-wrap gap-y-8 px-9 pt-6" data-sentry-component="Body" data-sentry-source-file="Card.tsx">{children}</div>;
}
export function Footer({
  children
}: Readonly<{
  children?: ReactNode | ReactNode[];
}>) {
  return <div className="pt-6 text-sm" data-sentry-component="Footer" data-sentry-source-file="Card.tsx">{children}</div>;
}
export function Summary({
  children
}: Readonly<{
  children?: ReactNode | ReactNode[];
}>) {
  return <div className="flex flex-1 flex-col justify-between" data-sentry-component="Summary" data-sentry-source-file="Card.tsx">{children}</div>;
}