import { ChevronDownIcon } from "@primer/octicons-react";
import { useState } from "react";
//import DropDown from "~/components/base/DropDown";
//import { useList } from "~/providers/ListProvider";
//import { useLists } from "~/providers/ListsProvider";
import {
// useSetTracker,
// useSetTrackerName,
useTracker, useTrackerName } from "~/providers/TrackerProvider";
//import type { Tracker } from "@openqlabs/drm-db";

export default function EditSelection() {
  const tracker = useTracker();
  const dependencies = (tracker?.dependencies as string[]);
  const several = dependencies?.length > 1;

  //const list = useList();
  //const { lists } = useLists();
  const trackerName = useTrackerName();
  // const setTracker = useSetTracker();
  // const setTrackerName = useSetTrackerName();
  const [open, setOpen] = useState(false);

  /* function handleSelect(trackerValue: string) {
    setOpen(false);
     const selectedTracker = list?.trackers.find(
      (tracker) => tracker.trackerName === trackerValue
    ) as Tracker;
    const allTrackers = lists.flatMap((list) => list.trackers);
    const listsTracker = allTrackers.find(
      (tracker) => tracker.trackerName === trackerValue
    );
    if (list?.trackers?.length || lists) {
      setTrackerName(trackerValue);
      setTracker(selectedTracker ?? listsTracker);
    }
  }*/
  /* const dropdownItems = trackerNames.map((trackerName) => ({
    name: trackerName,
  }));
  */
  const toggleModal = () => {
    if (open) null;else setOpen(true);
  };
  if (!trackerName) return null;
  return <div className="flex h-8 items-center justify-end text-sm" data-sentry-component="EditSelection" data-sentry-source-file="EditSelection.tsx">
      <div className="flex items-center gap-2">
        Tracked dependenc{`${several ? "ies" : "y"}`}:
        {<>
            {trackerName && <div className="relative w-fit">
                <button onClick={toggleModal} className="input-field flex w-full content-center items-center gap-2 px-2 py-1">
                  {trackerName}
                  <ChevronDownIcon className="  " />
                </button>

                {/*   <DropDown
                  className="top-10 w-fit bg-offwhite"
                  filterOpen={open}
                  items={dropdownItems}
                  selectItem={handleSelect}
                  closeDropDown={() => setOpen(false)}
                 />*/}
              </div>}
          </>}
      </div>
    </div>;
}