import CardActivityChart from "~/components/shared/ActivityChart";
import GenerateSharedActivityReportButton from "~/components/contact/shared/details/GenerateSharedActivityReportButton";
import { useLatestEvaluation } from "~/providers/EvaluationProvider";
import { type UserEvaluation } from "@openqlabs/drm-db";
export default function ChangesTab() {
  const latestEvaluation = useLatestEvaluation<UserEvaluation>();
  if (!latestEvaluation?.result) {
    return <div className="p-8 text-lg">Waiting for evaluation</div>;
  }
  return <div className="pt-6" data-sentry-component="ChangesTab" data-sentry-source-file="Changes.tsx">
      <CardActivityChart data-sentry-element="CardActivityChart" data-sentry-source-file="Changes.tsx" />
      <div className="flex items-center justify-center space-x-6 py-6">
        <GenerateSharedActivityReportButton type="user" title="Commit Summary" data-sentry-element="GenerateSharedActivityReportButton" data-sentry-source-file="Changes.tsx">
          Summary
        </GenerateSharedActivityReportButton>
      </div>
    </div>;
}