import UserCard from "~/components/lists/MainPage/Cards/UserCard";
import { useVirtualizer } from "@tanstack/react-virtual";
import { TrackerProvider } from "~/providers/TrackerProvider";
import useFetchWhenDone from "~/hooks/useFetchWhenDone";
import { getScrollElement } from "~/components/lists/MainPage/SharedTable/utils";
import { TeamRenderContact } from "~/providers/ContactProvider";
import { useLists } from "~/providers/ListsProvider";
import useVirtualPadding from "~/hooks/useVirtualPadding";
const ListUsers = ({
  userContactMap,
  handleGetNextUserPage,
  hasNextUserPage,
  isFetchingNextUsersPage
}: {
  userContactMap: TeamRenderContact[];
  handleGetNextUserPage: () => void;
  hasNextUserPage?: boolean;
  isFetchingNextUsersPage?: boolean;
}) => {
  const {
    lists
  } = useLists();
  const rowCount = userContactMap.length;
  const rowVirtualizer = useVirtualizer({
    getScrollElement,
    count: rowCount,
    estimateSize: () => 300,
    overscan: 20
  });
  const virtualRows = rowVirtualizer.getVirtualItems();
  useFetchWhenDone(handleGetNextUserPage, virtualRows, rowCount, isFetchingNextUsersPage, hasNextUserPage);
  const [paddingTop, paddingBottom] = useVirtualPadding(rowVirtualizer);
  return <div id="table-container" className="xl grid h-[calc(100vh_-_270px)] w-full grid-cols-1 gap-y-6 overflow-scroll @8xl:grid-cols-2" data-sentry-component="ListUsers" data-sentry-source-file="ListUsers.tsx">
      {paddingTop > 0 && <tr>
          <td style={{
        height: `${paddingTop}px`
      }} />
        </tr>}
      {virtualRows.map(virtualRow => {
      const contact = userContactMap[virtualRow.index];
      const list = lists.find(c => c.id === contact?.listId);
      return <TrackerProvider key={contact?.id ?? "key"} initialTracker={list?.trackers[0]}>
            <UserCard listContact={(contact as NonNullable<TeamRenderContact>)} />
          </TrackerProvider>;
    })}

      {paddingBottom > 0 && <tr>
          <td style={{
        height: `${paddingBottom}px`
      }} />
        </tr>}
    </div>;
};
export default ListUsers;