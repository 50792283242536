import Notification from "~/components/base/Notification";
import Link from "next/link";
import { useEffect, useState } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
import UserDependenciesFoundPresentation from "~/components/shared/UserDependenciesPresentation";
import SelectTrackerSimple from "~/components/contact/shared/details/dependencies/SelectTrackerSimple";
import { getDependenciesText } from "~/lib/utils";
import { useLatestDepsEvaluation } from "~/providers/DepsEvaluationProvider";
import type { Tracker, UserDependenciesEvaluation } from "@openqlabs/drm-db";
import { useTracker, useTrackerName } from "~/providers/TrackerProvider";
import { isEnv } from "~/lib/isEnv";
import { useContact } from "~/providers/ContactProvider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import UserActivityHistoryChart from "~/components/lists/MainPage/Cards/UserCard/tabs/history/UserActivityHistoryChart";
export default function DependenciesTab() {
  const latestUserDependenciesEvaluation = useLatestDepsEvaluation<UserDependenciesEvaluation>();
  const {
    contact
  } = useContact();
  const currentTracker = (useTracker() as Tracker);
  const listId = currentTracker?.listId ?? "";
  const [isEvaluatingUser, setIsEvaluatingUser] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const userId = contact?.teamContactId ?? "";
  const trackerName = (useTrackerName() as string);
  useEffect(() => {
    if (latestUserDependenciesEvaluation?.error) setIsError(true);else setIsError(false);
    if (latestUserDependenciesEvaluation?.status === "waiting") setIsEvaluatingUser(true);else setIsEvaluatingUser(false);
  }, [latestUserDependenciesEvaluation]);
  const reposContributedTo = latestUserDependenciesEvaluation?.result?.userRepoSummary ? Object.keys(latestUserDependenciesEvaluation.result.userRepoSummary) : [];
  const dependenciesSearched = currentTracker?.dependencies;
  const dependenciesFound = (latestUserDependenciesEvaluation?.result?.status != "cold" && latestUserDependenciesEvaluation?.result?.status !== "inactive" && latestUserDependenciesEvaluation?.result?.status !== undefined) ?? false;
  const isEvaluatingUserText = isEvaluatingUser ? "Searching for dependencies within the contact repository..." : getDependenciesText(dependenciesFound, dependenciesSearched, "user");
  const finalText = latestUserDependenciesEvaluation ? isEvaluatingUserText : "No evaluation found for this tracker yet.";
  const userRepoSummary = latestUserDependenciesEvaluation?.result?.userRepoSummary;
  return <div className={`p-4 text-sm`} data-sentry-component="DependenciesTab" data-sentry-source-file="Dependencies.tsx">
      <div className={`w-full space-y-3 px-8 pb-8`}>
        <div className={`flex justify-between pt-8`}>
          <h2 className="flex items-center gap-1 text-lg font-bold">
            Dependencies status of user{" "}
          </h2>
        </div>
        {userRepoSummary && <UserActivityHistoryChart title={``} userRepoSummary={userRepoSummary} dependency={currentTracker?.dependencies[0]} />}

        {isEvaluatingUser && <div className="flex items-center gap-2 text-center text-gray-600">
            Searching for dependencies within the repository...{" "}
            <LoadingSpinner />
          </div>}
        {!trackerName && <div className="flex items-center gap-2 text-center text-gray-600">
            No dependencies evaluated yet.
          </div>}

        {!isEvaluatingUser && dependenciesSearched?.length > 0 && <>
            <div className="flex items-center justify-between">
              <div>
                {!isError && finalText}
                {isError && isEnv("development") && <Notification id="dependencies">
                    An error occurred while evaluating dependencies. Please try
                    again later.
                  </Notification>}
                {isError && !isEnv("development") && <div className="my-4 w-fit px-2 py-1 text-sm">
                    Please run evaluation.
                  </div>}
              </div>
              <div className="flex items-center gap-2">
                <div className="whitespace-nowrap">Analyzed via</div>
                <SelectTrackerSimple />
              </div>
            </div>
            {latestUserDependenciesEvaluation && !isError && <UserDependenciesFoundPresentation latestDepsEvaluation={latestUserDependenciesEvaluation} dependenciesSearched={dependenciesSearched} trackerName={trackerName} />}
            {!isError && <div>
                <Link className="cursor-pointer text-blue-500 underline flex gap-1 items-center" href={{
            pathname: `/contact/user/${userId}/status/${listId}`
          }} target="_blank">
                  {reposContributedTo?.length} user repositories were analyzed
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
                </Link>
              </div>}
          </>}
      </div>
    </div>;
}