import { UserIcon } from "@heroicons/react/24/outline";
import { createColumnHelper, type Row } from "@tanstack/react-table";
import Link from "next/link";
import { getInfoValue, Skeleton } from "../SharedTable/utils";
import type { UltraLightlistContact } from "@openqlabs/drm-db";
const columnHelper = createColumnHelper<UltraLightlistContact | null>();
const EmailHandler = ({
  email
}: {
  email: string;
}) => {
  return <Link href={`mailto:${email}`} data-sentry-element="Link" data-sentry-component="EmailHandler" data-sentry-source-file="Columns.tsx">{email}</Link>;
};
export const getInfoValueTeam = <T,>(info: {
  row: Row<UltraLightlistContact | null>;
  getValue: () => T | null;
}): T | undefined => {
  return getInfoValue<T, UltraLightlistContact | null>(info);
};
export const ultraLightColumns = [columnHelper.accessor("githubName", {
  header: () => <span>Name</span>,
  cell: info => <div className="flex h-max items-stretch py-2  text-left ">
        <div className="w-12 px-3 text-right text-gray-400/50">
          {info.row.index + 1}
        </div>{" "}
        {info.row.original ? <>
            {<div className="flex h-full w-72 gap-4 px-2">
                <UserIcon className="h-6 w-6" />{" "}
                {getInfoValueTeam<string>(info)}
              </div>}
          </> : <div className="w-72">
            <Skeleton />
          </div>}
      </div>,
  footer: info => info.column.id
}), columnHelper.accessor("email", {
  header: "Email Addresses",
  cell: info => {
    const email = getInfoValueTeam<string>(info);
    return <div className="w-72 truncate px-2 text-sm">
          {info.row.original ? <>{email && <EmailHandler email={email} />}</> : <Skeleton />}
        </div>;
  },
  footer: info => info.column.id
})];