import { type Table, flexRender } from "@tanstack/react-table";
import type { TeamRenderContact } from "~/providers/ContactProvider";
import { useList } from "~/providers/ListProvider";
import type { Dispatch, SetStateAction } from "react";
import { stickToRight } from "../SharedTable/utils";
import { ChevronDownIcon, ChevronUpIcon, PlusIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { useSortParams } from "~/providers/SortParamsProvider";
const Header = ({
  table,
  setShowNewFieldModal,
  hideFinalColumn
}: {
  table: Table<TeamRenderContact>;
  setShowNewFieldModal: Dispatch<SetStateAction<boolean>>;
  hideFinalColumn?: boolean;
}) => {
  const list = useList();
  const headerIdToFieldMap: Record<string, string> = {
    dependencyStatusNumber: "dependencyStatusNumber",
    activity: "activity",
    reputation: "reputation",
    popularity: "popularity",
    lastUpdated: "lastUpdated",
    commitCount: "commitCount",
    totalStars: "totalStars",
    name: "githubName",
    totalFollowers: "followers",
    latestCommitDate: "latestCommitDate"
  };
  const {
    repoSort,
    setRepoSort
  } = useSortParams();
  const handleSort = (field: string) => {
    const isAlreadyThisField = repoSort?.field === field;
    const isAlreadyDesc = repoSort?.direction === "desc";
    const initialDirection = isAlreadyDesc ? "asc" : "desc";
    const direction = !isAlreadyThisField ? repoSort?.direction : initialDirection;
    setRepoSort({
      field,
      direction
    });
  };
  return <thead className="sticky top-0 z-30 bg-offwhite pt-12" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      {table.getHeaderGroups().map(headerGroup => {
      return <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
          const firstClass = index === 0 ? stickToRight : " border-x";
          const roundedClass = firstClass + " ";
          const field = headerIdToFieldMap[header.column.id];
          return <th className={`p-0 text-left bg-grey-50 font-normal  ${roundedClass}`} key={header.id}>
                  <div className="w-full px-2 py-3">
                    {header.isPlaceholder ? null : <button className={header.column.getCanSort() ? "flex select-none items-center justify-start gap-2" : "flex"} onClick={() => handleSort(field)}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {field && <>
                            {field !== repoSort?.field && <ChevronUpDownIcon className="w-4 h-4" />}
                            {field === repoSort?.field && repoSort.direction === "desc" && <ChevronDownIcon className="w-4 h-4" />}
                            {field === repoSort?.field && repoSort.direction === "asc" && <ChevronUpIcon className="w-4 h-4" />}
                          </>}
                      </button>}
                  </div>
                </th>;
        })}
            {!hideFinalColumn && <th className=" sticky top-0 z-40 border-b border-r border-gray-100 bg-gray-50 hover:bg-gray-100">
                {list && <button className="w-12 " onClick={() => {
            setShowNewFieldModal(true);
          }}>
                    <PlusIcon className="h-6 w-12  text-gray-400" />
                  </button>}
              </th>}
          </tr>;
    })}
    </thead>;
};
export default Header;