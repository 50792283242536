import { ChevronDownIcon, ChevronUpIcon, OrganizationIcon, RepoIcon, InfoIcon } from "@primer/octicons-react";
import { createColumnHelper, type CellContext, type Row, type AccessorFnColumnDef } from "@tanstack/react-table";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useMemo, useState, type FormEvent } from "react";
import { getLangIcon } from "~/components/lists/MainPage/SharedTable/LanguageBadges";
import { getColorFromPercentage, getSimplifiedStatus } from "~/lib/utils";
import { decodeStatusAsObject, nukeNegatives } from "@openqlabs/utils";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { trpc } from "~/store/trpc";
import { CellWithPill, LoadingWrapper } from "../Cells";
import { getPrimaryRepo, getInfoValue, Skeleton, TruncatedString } from "../SharedTable/utils";
import { CustomField } from "~/server/api/model/list";
import Contributor, { UserSocialProps } from "../UsersTable/UserSocials";
import { useUpdateCustomFieldValue } from "../SharedTable/hooks/useUpdateCustomFieldValue";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useListRefetch } from "~/providers/ListProvider";
import { TeamRenderContact } from "~/providers/ContactProvider";
import DropDown from "~/components/base/DropDown";
import { handleRefetchList } from "../Toast/index";
import useListParams from "../SharedTable/hooks/useListParams";
import { api } from "~/utils/api";
import LoadingSpinner from "~/loaders/LoadingSpinner";
const columnHelper = createColumnHelper<TeamRenderContact>();
export const getInfoValueTeam = <T,>(info: {
  row: Row<TeamRenderContact>;
  getValue: () => T | null;
}): T | undefined => {
  return getInfoValue<T, TeamRenderContact>(info);
};
export const EditableCell = ({
  initialValue,
  updater,
  keyString,
  customFieldId,
  contactId,
  type
}: {
  keyString: string;
  initialValue: string | null | undefined;
  updater: (value: string, keyString: string) => Promise<unknown>;
  customFieldId: string;
  contactId: string;
  type: "repo" | "user";
}) => {
  const [currentValue, setCurrentValue] = useState<string>(initialValue ?? "");
  const localUpdater = useUpdateCustomFieldValue(type, contactId, customFieldId, currentValue);
  const handleBlur = () => {
    if (currentValue === initialValue || !currentValue && !initialValue) return;
    updater(currentValue ?? "", keyString).catch(console.error);
    localUpdater(customFieldId);
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleBlur);
    window.addEventListener("unload", handleBlur);
    return () => {
      window.removeEventListener("beforeunload", handleBlur);
      window.removeEventListener("unload", handleBlur);
    };
  }, [currentValue, window, handleBlur]);
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const contact = (e.target as HTMLInputElement);
    setCurrentValue(contact.value);
  };
  return <input className="h-full w-full px-2 text-sm focus:outline-none" onBlur={handleBlur} onInput={handleChange} tabIndex={0} value={currentValue} type="text" data-sentry-component="EditableCell" data-sentry-source-file="Columns.tsx" />;
};

// Fixed widths on headers are ESSENTIAL to ensure that the table is not janky

export const useCreateRepoColumns = (customFields = ([] as CustomField[])) => {
  const {
    activeTeamAccount
  } = useTeamAccount();
  const {
    user,
    repo
  } = useListParams();
  const utils = api.useUtils();
  const refetchList = useListRefetch();
  return (useMemo(() => {
    const handleUpdates = (value: string, keyString: string, info: CellContext<TeamRenderContact, string>, customField: CustomField) => {
      const leafRows = info.row.getLeafRows();
      const primaryRow = getPrimaryRepo(leafRows) ?? info.row;
      if (!primaryRow?.original) return Promise.resolve();
      const currentContactId = primaryRow?.original?.id;
      const customFieldId = customField?.id;
      return trpc.customField.update.mutate({
        customFieldId: customFieldId,
        teamAccountId: activeTeamAccount.id,
        key: keyString,
        value: value,
        contactId: currentContactId
      }).catch(console.error);
    };
    const customFieldColumns = customFields.map((customField, index) => {
      const accessor = (customField.key as keyof NonNullable<TeamRenderContact>);
      const header = (customField.key as keyof NonNullable<TeamRenderContact>) ?? "N/A";
      const cell = (info: CellContext<TeamRenderContact, string>) => {
        const handleUpdatesWithinCell = (value: string, keyString: string) => {
          return handleUpdates(value, keyString, info, customField);
        };
        const initialValue = info.row?.original?.customFieldValues?.find(field => field.customFieldId === customField.id)?.value_string;
        return <div className="w-72 truncate px-2 text-sm " data-sentry-component="cell" data-sentry-source-file="Columns.tsx">
            {info?.row?.original ? <EditableCell type="repo" contactId={info.row.original.id} customFieldId={customField.id} initialValue={initialValue} keyString={accessor} updater={handleUpdatesWithinCell} /> : <Skeleton />}
          </div>;
      };
      const aggregatedCell = (info: CellContext<TeamRenderContact, string>) => {
        const handleUpdatesWithinCell = (value: string, keyString: string) => {
          return handleUpdates(value, keyString, info, customField);
        };
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        const initialValue = primaryRow?.original?.customFieldValues.find(field => field.customFieldId === customField.id)?.value_string;
        if (info.row.getIsExpanded()) return "";
        return <div className="w-72 truncate px-2 text-sm " data-sentry-component="aggregatedCell" data-sentry-source-file="Columns.tsx">
            <EditableCell type="repo" contactId={(primaryRow.original?.id as string)} customFieldId={customField.id} initialValue={initialValue} keyString={accessor} updater={handleUpdatesWithinCell} data-sentry-element="EditableCell" data-sentry-source-file="Columns.tsx" />
          </div>;
      };
      return columnHelper.accessor(accessor, {
        header: () => {
          const handleDelete = async () => {
            await trpc.customField.deleteColumn.mutate({
              customFieldId: customField.id,
              teamAccountId: activeTeamAccount.id
            });
            await refetchList();
          };
          return <div className="flex gap-2">
              {header?.length > 30 ? <div className="flex group">
                  <div className="flex gap-1">
                    <span className="w-60 truncate">{header}</span>
                  </div>
                  <div className="relative z-50 hidden group-hover:block">
                    <div className="absolute w-80 -ml-72 mt-8 bg-white px-1 text-sm">
                      {header}
                    </div>
                  </div>
                </div> : header}
              <button onClick={handleDelete}>
                <TrashIcon className="sm-icon hover:text-red-500" />
              </button>
            </div>;
        },
        cell,
        id: index.toString(),
        aggregatedCell,
        footer: () => null
      });
    });
    return [columnHelper.accessor("owner", {
      header: () => <span className="w-[338px] text-left">Owner</span>,
      cell: info => {
        const leafsOverOne = (array: Row<TeamRenderContact>[]) => {
          return array.length > 1;
        };
        const isExpanded = info.row.getIsExpanded();
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        return <div className="flex h-max items-stretch py-2 w-72 text-left ">
              <div className="w-12 px-3 text-right flex content-center items-center">
                {info.row.index + 1}
              </div>
              {getInfoValueTeam(info) ? <div className="h-full  px-2 underline" style={{
            textDecorationColor: "#e0dede",
            textUnderlineOffset: "0.25em"
          }}>
                  <div className="flex items-center gap-4">
                    {leafsOverOne(info.row.getLeafRows()) ? <OrganizationIcon className="h-6 w-6" /> : <RepoIcon className="h-6 w-6" />}
                    {primaryRow.original && !leafsOverOne(info.row.getLeafRows()) ? <Link href={`/contact/repo/${primaryRow.original.teamContactId}/commits/activity`}>
                        {getInfoValueTeam<string>(info)}
                      </Link> : <> {getInfoValueTeam<string>(info)}</>}
                    {leafsOverOne(info.row.getLeafRows()) && (!isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />)}
                  </div>
                </div> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("name", {
      header: () => <span className="w-60 text-left">Name</span>,
      aggregatedCell: info => {
        if (info.row.getIsExpanded()) return "";
        const leafRows = info.row.getLeafRows();
        const primaryRow = getPrimaryRepo(leafRows);
        const name = primaryRow.original?.name;
        return <div className="flex h-max items-stretch">
              <div className="h-full px-2 underline" style={{
            textDecorationColor: "#e0dede",
            textUnderlineOffset: "0.25em"
          }}>
                {info.row.original ? <Link href={`/contact/repo/${info.row.original.teamContactId}/commits/activity`}>
                    <>{name}</>
                  </Link> : <Skeleton />}
              </div>
            </div>;
      },
      cell: info => {
        return <div className="flex w-60 gap-4 h-max items-stretch px-4">
              {info.row.original ? <>
                  <RepoIcon className="h-6 w-6" />
                  <div className="h-full underline" style={{
              textDecorationColor: "#e0dede",
              textUnderlineOffset: "0.25em"
            }}>
                    <Link href={`/contact/repo/${info.row.original.teamContactId}/commits/activity`}>
                      {info.row.original.name}
                    </Link>
                  </div>
                </> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("dependencyStatusNumber", {
      header: () => <div className="w-32">Engagement</div>,
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        if (info.row.getIsExpanded()) return "";
        const depsStatusNumber = primaryRow.original?.dependencyStatusNumber;
        const manualStatus = info.row.original?.manualStatus;
        const depsStatusObj = decodeStatusAsObject(depsStatusNumber, manualStatus);
        return <>
              {info.row.original && primaryRow.original ? <LoadingWrapper showSpinner={!!info.row.original.waitingOnDepsEval}>
                  <div className={`flex w-full flex-nowrap justify-center gap-8 gap-x-4 pl-2 pr-2`}>
                    <div className={`flex flex-col flex-nowrap gap-x-4 pl-2 pr-2`}>
                      <div className="flex gap-2">
                        <div className={`group m-auto flex whitespace-nowrap rounded-md px-4 text-sm ${depsStatusObj.color}`}>
                          {depsStatusObj.status}
                          <div className="relative z-10 opacity-0 group-hover:opacity-100">
                            <div className="absolute -ml-40 -mt-4 whitespace-nowrap bg-white px-1">
                              {depsStatusObj.tooltip}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      cell: info => {
        if (info.row.getIsExpanded()) return "";
        const depsStatusNumber = getInfoValueTeam<number | null>(info);
        const manualStatus = info.row.original?.manualStatus;
        const depsStatusObj = decodeStatusAsObject(depsStatusNumber, manualStatus);
        return <>
              {info.row.original ? <LoadingWrapper showSpinner={info.row.original.githubContactId !== "" && !!info.row.original.waitingOnDepsEval}>
                  <div className={`flex w-full flex-nowrap justify-center gap-8 gap-x-4 pl-2 pr-2`}>
                    <div className={`flex flex-col flex-nowrap gap-x-4 pl-2 pr-2`}>
                      <div className="flex gap-2">
                        <div className={`group m-auto flex whitespace-nowrap rounded-md px-4 text-sm ${depsStatusObj.color}`}>
                          {depsStatusObj.status}
                          <div className="relative z-40 opacity-0 group-hover:opacity-100">
                            <div className="absolute -ml-40 -mt-8 whitespace-nowrap bg-white px-1">
                              {depsStatusObj.tooltip}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      footer: info => info.column.id,
      size: 400
    }), columnHelper.accessor("dependencyStatusNumber", {
      header: () => <div className="w-32">Usage Status</div>,
      aggregatedCell: info => {
        const props = info.row.original;
        if (!props) return <Skeleton />;
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        if (info.row.getIsExpanded()) return "";
        const [showDropdown, setShowDropdown] = useState(false);
        const [closeDropdown, setCloseDropdown] = useState(false);
        const [updatingStatus, setUpdatingStatus] = useState(false);
        const depsStatusNumber = primaryRow.original?.dependencyStatusNumber;
        const simplifiedStatus = getSimplifiedStatus(depsStatusNumber);
        const manualStatus = props?.manualStatus;
        const isEvalutedActive = !manualStatus && [1, 2, 3, 13].includes((depsStatusNumber as number));
        const simplifiedStatusObj = decodeStatusAsObject(simplifiedStatus, manualStatus);
        const newStatus = manualStatus ? null : 13;
        const handleStatusChange = async () => {
          setUpdatingStatus(true);
          await trpc.listContact.updateManualStatus.mutate({
            id: props.id,
            teamAccountId: activeTeamAccount.id,
            manualStatus: newStatus,
            listId: (props.listId as string)
          });
          await handleRefetchList({
            activeTeamAccountId: activeTeamAccount.id,
            listId: (props.listId as string),
            user,
            repo,
            utils
          });
          setUpdatingStatus(false);
        };
        return <>
              {info.row.original && primaryRow.original ? <LoadingWrapper showSpinner={!!info.row.original.waitingOnDepsEval}>
                  <div className={`flex flex-nowrap justify-center gap-8 gap-x-4 pl-2 pr-2 w-36`}>
                    <div className={`flex flex-col flex-nowrap gap-x-4 pl-2 pr-2`}>
                      <div className="flex gap-2">
                        <button disabled={isEvalutedActive || updatingStatus} onClick={() => setShowDropdown(!showDropdown)} className={`relative group m-auto flex items-center gap-1 whitespace-nowrap rounded-md pl-4 pr-2 text-sm ${simplifiedStatusObj.color}`}>
                          {simplifiedStatusObj.status}{" "}
                          {!isEvalutedActive && <ChevronDownIcon className="h-3 w-3" />}
                          <LoadingSpinner show={updatingStatus} className="h-3 w-3 text-gray-400" />
                          {showDropdown && !closeDropdown && !updatingStatus && <DropDown className="absolute w-fit hover:bg-gray-200 right-0 top-6 bg-offwhite font-normal" filterOpen={showDropdown} items={[{
                      name: `${manualStatus ? "Deactivate manual status" : "Mark as dependency active"}`,
                      id: "manualStatus"
                    }]} selectItem={handleStatusChange} closeDropDown={() => setCloseDropdown(false)} />}
                          <div className={`relative z-20 opacity-0 ${showDropdown ? "" : "group-hover:opacity-100"}`}>
                            <div className="absolute -ml-40 -mt-7 whitespace-nowrap bg-white px-1">
                              {simplifiedStatusObj.tooltip}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      cell: info => {
        if (info.row.getIsExpanded()) return "";
        const props = info.row.original;
        if (!props) return <Skeleton />;
        const [showDropdown, setShowDropdown] = useState(false);
        const [closeDropdown, setCloseDropdown] = useState(false);
        const [updatingStatus, setUpdatingStatus] = useState(false);
        const depsStatusNumber = getInfoValueTeam<number | null>(info);
        const simplifiedStatus = getSimplifiedStatus(depsStatusNumber);
        const manualStatus = props?.manualStatus;
        const isEvalutedActive = !manualStatus && [1, 2, 3, 13].includes((depsStatusNumber as number));
        const simplifiedStatusObj = decodeStatusAsObject(simplifiedStatus, manualStatus);
        const newStatus = manualStatus ? null : 13;
        const handleStatusChange = async () => {
          setUpdatingStatus(true);
          await trpc.listContact.updateManualStatus.mutate({
            id: props.id,
            teamAccountId: activeTeamAccount.id,
            manualStatus: newStatus,
            listId: (props.listId as string)
          });
          await handleRefetchList({
            activeTeamAccountId: activeTeamAccount.id,
            listId: (props.listId as string),
            user,
            repo,
            utils
          });
          setUpdatingStatus(false);
        };
        return <>
              {info.row.original ? <LoadingWrapper showSpinner={info.row.original.githubContactId !== "" && !!info.row.original.waitingOnDepsEval}>
                  <div className={`flex flex-nowrap justify-center gap-8 gap-x-4 pl-2 pr-2  w-36`}>
                    <div className={`flex flex-col flex-nowrap gap-x-4 pl-2 pr-2`}>
                      <div className="flex gap-2">
                        <button disabled={isEvalutedActive || updatingStatus} onClick={() => setShowDropdown(!showDropdown)} className={`relative group m-auto flex items-center gap-1 whitespace-nowrap rounded-md pl-4 pr-2 text-sm ${simplifiedStatusObj.color}`}>
                          {simplifiedStatusObj.status}
                          {!isEvalutedActive && <ChevronDownIcon className="h-3 w-3" />}
                          <LoadingSpinner show={updatingStatus} className="h-3 w-3 text-gray-400" />

                          {showDropdown && !closeDropdown && !updatingStatus && <DropDown className="absolute w-fit hover:bg-gray-200 right-0 top-6 bg-offwhite font-normal" filterOpen={showDropdown} items={[{
                      name: `${manualStatus ? "Deactivate manual status" : "Mark as dependency active"}`,
                      id: "manualStatus"
                    }]} selectItem={handleStatusChange} closeDropDown={() => setCloseDropdown(false)} />}
                          <div className={`relative z-20 opacity-0 ${showDropdown ? "" : "group-hover:opacity-100"}`}>
                            <div className="absolute -ml-40 -mt-7 whitespace-nowrap bg-white px-1">
                              {simplifiedStatusObj.tooltip}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      footer: info => info.column.id,
      size: 400
    }), columnHelper.accessor("description", {
      header: () => <div className="w-96 text-left">Description</div>,
      cell: info => {
        return <div className={` flex text-sm `}>
              {info.row.original ? <TruncatedString string={getInfoValueTeam<string>(info) ?? ""} maxLength={35} /> : <div className="px-2">
                  <Skeleton />
                </div>}
            </div>;
      },
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        const description = primaryRow.original?.description;
        if (info.row.getIsExpanded()) return "";
        return <div className={` flex text-sm `}>
              {<TruncatedString string={description ?? ""} maxLength={35} /> ?? <div className="px-2">
                  <Skeleton />
                </div>}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("latestCommitDate", {
      header: () => <div className="whitespace-nowrap flex items-center gap-2 w-36">
            Latest Commit
            <div className="group flex">
              <InfoIcon />
              <div className="relative z-50 opacity-0 group-hover:opacity-100">
                <div className="absolute ml-0 -mt-4 whitespace-nowrap bg-white px-1 text-sm">
                  in past 6 weeks
                </div>
              </div>
            </div>
          </div>,
      cell: info => {
        const latestCommitDate = getInfoValueTeam<number>(info);
        const formattedCommitDate = latestCommitDate ? new Date(latestCommitDate).toLocaleDateString() : "no commits";
        return <LoadingWrapper showSpinner={!!info.row.original?.waitingOnEval}>
              <div className={`justify-center flex text-sm w-32 px-2`}>
                {formattedCommitDate}
              </div>
            </LoadingWrapper>;
      },
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        const latestCommitDate = primaryRow.original?.latestCommitDate;
        const formattedCommitDate = latestCommitDate ? new Date(latestCommitDate).toLocaleDateString() : "no commits";
        if (info.row.getIsExpanded()) return "";
        return <LoadingWrapper showSpinner={!!primaryRow.original?.waitingOnEval}>
              <div className={`justify-center flex text-sm w-32 px-2 `}>
                {formattedCommitDate ? formattedCommitDate : <div className="px-2">
                    <Skeleton />
                  </div>}
              </div>
            </LoadingWrapper>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("socialData", {
      header: () => <div className="text-left w-40">Socials</div>,
      cell: info => <div className="px-2 pr-2 text-sm">
            {info.row.original ? <Contributor mentionableUser={getInfoValueTeam<UserSocialProps>(info) ?? {}} /> : <Skeleton />}
          </div>,
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        if (info.row.getIsExpanded()) return "";
        return <div className="px-2 pr-8 text-sm">
              {primaryRow.original ? <Contributor mentionableUser={primaryRow.original?.socialData ?? null} /> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("activity", {
      header: () => <div className="text-left w-16">Activity</div>,
      aggregatedCell: info => {
        const rows = info.row.getLeafRows();
        const primaryRepo = getPrimaryRepo(rows);
        if (!primaryRepo.original) return <Skeleton />;
        const activity = (primaryRepo.original.activity ?? 0) * 100;
        if (info.row.getIsExpanded()) return "";
        const display = primaryRepo.original.activity !== undefined ? activity.toFixed() + "%" : "N/A";
        return <LoadingWrapper showSpinner={primaryRepo.original.waitingOnEval}>
              <CellWithPill color={getColorFromPercentage(activity)}>
                {display}
              </CellWithPill>
            </LoadingWrapper>;
      },
      cell: info => {
        const activity = (getInfoValueTeam<number | null>(info) ?? 0) * 100;
        const display = getInfoValueTeam(info) !== undefined ? `${activity.toFixed()}%` : "N/A";
        if (!info.row.original) return <Skeleton />;
        if (info.row.getIsExpanded()) return "";
        return <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
              <CellWithPill color={getColorFromPercentage(activity)}>
                {display}
              </CellWithPill>
            </LoadingWrapper>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("languages", {
      header: () => <div className="text-left whitespace-pre w-40">Top Languages</div>,
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        if (info.row.getIsExpanded()) return "";
        return <div className=" flex gap-2 px-2">
              {primaryRow.original ? <LoadingWrapper showSpinner={primaryRow.original.waitingOnEval}>
                  {primaryRow.original.languages?.filter(getLangIcon).map((elem: string) => <div key={elem} className="h-6 w-6">
                        {getLangIcon(elem)}
                      </div>)}
                </LoadingWrapper> : <Skeleton />}
            </div>;
      },
      cell: info => {
        const languageArray = getInfoValueTeam<string[]>(info);
        if (info.row.getIsExpanded()) return "";
        return <div className=" flex gap-2 px-2">
              {info.row.original ? <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  {languageArray?.filter(getLangIcon).map(elem => <div key={elem} className="h-6 w-6">
                      {getLangIcon(elem)}
                    </div>)}
                </LoadingWrapper> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("reputation", {
      header: () => <div className="text-left w-24">Reputation</div>,
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        const reputation = primaryRow.getValue<number>("reputation");
        // eslint-disable-next-line no-extra-boolean-cast
        const display = !!reputation ? `${nukeNegatives(reputation.toFixed(0))} / 10` : "N/A";
        if (info.row.getIsExpanded()) return "";
        if (!primaryRow.original) return <Skeleton />;
        return <LoadingWrapper showSpinner={primaryRow.original.waitingOnEval}>
              <CellWithPill color={"bg-warn"}>{display}</CellWithPill>
            </LoadingWrapper>;
      },
      cell: info => {
        const display = getInfoValueTeam(info) ? `${nukeNegatives((getInfoValueTeam<number | null>(info) ?? 0).toFixed(0))} / 10` : "N/A";
        if (!info.row.original) return <Skeleton />;
        if (info.row.getIsExpanded()) return "";
        return <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
              <CellWithPill color={"bg-warn"}>{display}</CellWithPill>
            </LoadingWrapper>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("popularity", {
      header: () => <div className="text-left w-24">Popularity</div>,
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        const popularity = primaryRow.getValue<number>("popularity");
        if (info.row.getIsExpanded()) return "";
        // eslint-disable-next-line no-extra-boolean-cast
        const display = !!popularity ? `${popularity.toFixed(0)} / 10` : "N/A";
        return <>
              {primaryRow.original ? <LoadingWrapper showSpinner={primaryRow.original.waitingOnEval}>
                  <CellWithPill color={"bg-warn"}>{display}</CellWithPill>
                </LoadingWrapper> : null}
            </>;
      },
      cell: info => {
        const display = getInfoValueTeam(info) !== undefined ? `${(getInfoValueTeam<number | null>(info) ?? 0).toFixed(0)} / 10` : "N/A";
        if (info.row.getIsExpanded()) return "";
        return <>
              {info.row.original ? <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  <CellWithPill color={"bg-warn"}>{display}</CellWithPill>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("commitCount", {
      header: () => <div className="w-24 flex items-center gap-2">
            <span>Commits</span>
            <div className="group flex">
              <InfoIcon />
              <div className="relative z-50 opacity-0 group-hover:opacity-100">
                <div className="absolute ml-0 -mt-4 whitespace-nowrap bg-white px-1 text-sm">
                  in past 6 weeks
                </div>
              </div>
            </div>
          </div>,
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        const commits = primaryRow.getValue<number>("commitCount");
        if (info.row.getIsExpanded()) return "";
        return <>
              {info.row.original ? <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  <CellWithPill color={"bg-accent-subtle text-white"}>
                    {commits}
                  </CellWithPill>
                </LoadingWrapper> : null}
            </>;
      },
      cell: info => {
        return <>
              {info.row.original ? <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  <CellWithPill color={"bg-accent-subtle text-white"}>
                    {getInfoValueTeam<number>(info)}
                  </CellWithPill>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("totalStars", {
      header: () => <span className="whitespace-pre text-left w-24">Stars</span>,
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        const totalStars = primaryRow.getValue<number>("totalStars");
        if (info.row.getIsExpanded()) return "";
        return <>
              {primaryRow.original ? <LoadingWrapper showSpinner={primaryRow.original.waitingOnEval}>
                  <CellWithPill color={"bg-accent-subtle text-white"}>
                    {totalStars}
                  </CellWithPill>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      cell: info => {
        if (info.row.getIsExpanded()) return "";
        return <>
              {getInfoValueTeam<number>(info) !== null ? <CellWithPill color="bg-offwhite">
                  {getInfoValueTeam<number>(info)}
                </CellWithPill> : <Skeleton />}
            </>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("contributors", {
      header: () => <span className="whitespace-pre w-44  text-left">
            Recent Contributors
          </span>,
      aggregatedCell: info => {
        const primaryRow = getPrimaryRepo(info.row.getLeafRows());
        const items = primaryRow.getValue<{
          avatarUrl: string;
          login: string;
        }[]>("contributors")?.slice(0, 5);
        if (info.row.getIsExpanded()) return "";
        return <>
              {primaryRow.original ? <LoadingWrapper showSpinner={primaryRow.original.waitingOnEval}>
                  <div className="flex w-max gap-2 px-2">
                    {items?.map((elem, index) => <div className="group flex" key={index.toString() + elem.avatarUrl}>
                          <Image width="24" height="24" alt="" src={elem.avatarUrl} className="rounded-full" />
                          <div className="relative z-40 opacity-0 group-hover:opacity-100">
                            <div className="absolute -ml-10 -mt-6 whitespace-nowrap bg-white px-1">
                              {elem.login}
                            </div>
                          </div>
                        </div>).slice(0, 5)}
                  </div>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      cell: info => {
        const items = getInfoValueTeam<{
          avatarUrl: string;
          login: string;
        }[]>(info)?.slice(0, 5);
        if (info.row.getIsExpanded()) return "";
        return <>
              {info.row.original ? <LoadingWrapper showSpinner={info.row.original.waitingOnEval}>
                  <div className=" flex w-max gap-2 px-2">
                    {items?.map((elem, index) => <div className="group flex" key={index.toString() + elem.avatarUrl}>
                          <Image width="24" height="24" alt="" src={elem.avatarUrl} className="rounded-full" />
                          <div className="relative z-40 opacity-0 group-hover:opacity-100">
                            <div className="absolute -ml-10 -mt-6 whitespace-nowrap bg-white px-1">
                              {elem.login}
                            </div>
                          </div>
                        </div>).slice(0, 5)}
                  </div>
                </LoadingWrapper> : <Skeleton />}
            </>;
      },
      footer: info => info.column.id
    }), ...customFieldColumns];
  }, [customFields, activeTeamAccount.id]) as AccessorFnColumnDef<TeamRenderContact>[]);
};