import { useState } from "react";
import DiscreetButton from "~/components/base/DiscreetButton";
import { Footer } from "~/components/layout/Card";
import ChangesTab from "~/components/lists/MainPage/Cards/UserCard/tabs/Changes";
import DependenciesTab from "~/components/lists/MainPage/Cards/UserCard/tabs/Dependencies";
import TabWrapper from "../../SharedCard/tabs/TabWrapper";
import { TeamRenderContact } from "~/providers/ContactProvider";
import { ContactProvider } from "~/providers/ContactProvider";
import { decodeStatusAsObject } from "@openqlabs/utils";
enum CardTabs {
  Changes = "changes",
  Dependencies = "dependencies",
}
export default function Tabs({
  listContact
}: {
  listContact: NonNullable<TeamRenderContact>;
}) {
  const [showTab, setShowTab] = useState<CardTabs | null>(null);
  function handleClickTab(tab: CardTabs) {
    if (showTab === tab) setShowTab(null);else setShowTab(tab);
  }
  const firstDep = decodeStatusAsObject(listContact.dependencyStatusNumber, undefined);

  /* const newLead = list?.lightMode
    ? null
    : Object.values(userStatusPerDependency ?? {}).some(
        (status) => status === "lead" || status === "lead inactive"
      ); */
  // currently only one dependency per tracker
  return <Footer data-sentry-element="Footer" data-sentry-component="Tabs" data-sentry-source-file="Tabs.tsx">
      <div className="flex">
        <DiscreetButton className={`w-full !rounded-b-none !rounded-tl-none !border-none text-sm font-normal hover:bg-offwhite ${showTab === CardTabs.Changes ? "bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300 " : ""}`} onClick={() => handleClickTab(CardTabs.Changes)} data-sentry-element="DiscreetButton" data-sentry-source-file="Tabs.tsx">
          Changes
        </DiscreetButton>
        <DiscreetButton className={`w-full !rounded-b-none !rounded-tr-none !border-none text-sm font-normal hover:bg-offwhite ${showTab === CardTabs.Dependencies ? "bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300 " : ""}`} onClick={() => handleClickTab(CardTabs.Dependencies)} data-sentry-element="DiscreetButton" data-sentry-source-file="Tabs.tsx">
          Dependencies{" "}
          <span className={`mx-2 whitespace-nowrap rounded-md ${firstDep.color} px-4 text-sm`}>
            {firstDep.status}
          </span>
        </DiscreetButton>
      </div>
      {!!showTab && <ContactProvider id={listContact.id}>
          <TabWrapper type="user">
            <div className={`${showTab === CardTabs.Changes ? "" : "max-h-0"} overflow-auto bg-offwhite transition-all dark:bg-gray-900/50`}>
              <ChangesTab />
            </div>
            <div className={`${showTab === CardTabs.Dependencies ? "" : "max-h-0"} overflow-auto bg-offwhite transition-all dark:bg-gray-900/50`}>
              <DependenciesTab />
            </div>
            <div className={`${showTab === CardTabs.Dependencies ? "" : "max-h-0"} overflow-auto bg-offwhite transition-all dark:bg-gray-900/50`}></div>
          </TabWrapper>
        </ContactProvider>}
    </Footer>;
}