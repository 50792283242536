import { Skeleton } from "../SharedTable/utils";
const SkeletonRow = ({
  numbering
}: {
  numbering: number;
}) => {
  return <tr key={"loader"} className="z-30 border-y" data-sentry-component="SkeletonRow" data-sentry-source-file="SkeletonRow.tsx">
      <td className="sticky left-0 top-0 z-30 overflow-hidden border-r border-r border-gray-100 bg-gray-50 text-right">
        <div className="flex h-max items-stretch py-2  text-left ">
          <div className=" px-3 text-right text-gray-400/50 ">{numbering}</div>
          <div className="flex   flex-1 animate-pulse px-2">
            <div className="h-6  rounded-md bg-gray-200/50"></div>
          </div>
        </div>
      </td>
      <td className="z-10 border-r border-gray-200">
        <div className="flex h-max items-stretch ">
          <div className="h-full  px-4 underline">
            <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
          </div>
        </div>
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
      </td>
      <td className="z-10 border-r border-gray-200">
        <div className=" truncate px-2 text-sm ">
          <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
        </div>
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
      </td>
      <td className="z-10 border-r border-gray-200">
        <div className=" flex  gap-2 px-2">
          <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
        </div>
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
      </td>
      <td className="z-10 border-r border-gray-200">
        <Skeleton data-sentry-element="Skeleton" data-sentry-source-file="SkeletonRow.tsx" />
      </td>
    </tr>;
};
export default SkeletonRow;