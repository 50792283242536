import Link from "next/link";
import { useState } from "react";
import Card, { Body, Summary } from "~/components/layout/Card";
import CardScores from "~/components/score/Scores";
import Stars from "~/components/lists/MainPage/Cards/SharedCard/summary/stars";
import CardHeader from "./RepoCardHeader";
import CardMembers from "./Members";
import Tabs from "./tabs/Tabs";
import useRemoveItemFromListList from "../../SharedTable/hooks/useRemoveItemFromList";
import { TeamRenderContact } from "~/providers/ContactProvider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
export enum CardTabs {
  Changes = "changes",
  Developers = "developers",
  Dependencies = "dependencies",
}
export default function RepoCard({
  listContact
}: Readonly<{
  listContact: TeamRenderContact;
}>) {
  const removeListListMember = useRemoveItemFromListList("repo");
  const [showTab, setShowTab] = useState<CardTabs | null>(null);
  if (!listContact) return null;
  function handleClickTab(tab: CardTabs) {
    if (showTab === tab) setShowTab(null);else setShowTab(tab);
  }
  const openDevTab = () => {
    handleClickTab(CardTabs.Developers);
  };
  const handleSetShowCard = () => {
    removeListListMember(listContact.id);
  };
  return <Card data-sentry-element="Card" data-sentry-component="RepoCard" data-sentry-source-file="index.tsx">
      <CardHeader listContact={listContact} setShowCard={handleSetShowCard} data-sentry-element="CardHeader" data-sentry-source-file="index.tsx" />
      <Body data-sentry-element="Body" data-sentry-source-file="index.tsx">
        <Summary data-sentry-element="Summary" data-sentry-source-file="index.tsx">
          <div>
            <div className="-mt-2 line-clamp-3">
              {listContact?.description ?? "No description available"}
            </div>
            {listContact !== null && <Link href={`https://github.com/${listContact.githubName}`} className="text-accent flex gap-1 items-center" target="_blank">
                Read more
                <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block" />
              </Link>}
          </div>
          <div className="-mb-3 flex gap-4 pt-4">
            <Stars data-sentry-element="Stars" data-sentry-source-file="index.tsx">{listContact?.totalStars ?? ""}</Stars>
            {/*BRING BACK FORKS AND ISSUE COUNT */}
          </div>
        </Summary>

        <CardMembers listContact={listContact} openDevTab={openDevTab} data-sentry-element="CardMembers" data-sentry-source-file="index.tsx" />

        <CardScores contactIsLoading={false} listContact={listContact} data-sentry-element="CardScores" data-sentry-source-file="index.tsx" />
      </Body>
      <Tabs listContact={listContact} handleClickTab={handleClickTab} showTab={showTab} data-sentry-element="Tabs" data-sentry-source-file="index.tsx" />
    </Card>;
}