import Link from "next/link";
import DashboardCard from "~/components/lists/MainPage/Dashboard/DashboardCard";
import { useList } from "~/providers/ListProvider";
const KPIs = ({
  setSubMenu,
  leads,
  newLeads,
  customers
}: {
  setSubMenu: (value: string) => void;
  leads: number;
  newLeads: number;
  customers: number;
}) => {
  const list = useList();
  const {
    budget
  } = list;
  const activeUsers = leads + customers + newLeads;
  const costPerListLead = `${leads === 0 ? "N/A" : `$${(budget / leads).toFixed(0)}`}`;
  const costPerLead = `${newLeads + leads === 0 ? "N/A" : `$${(budget / (newLeads + leads)).toFixed(0)}`}`;
  const listLeadTitle = `${costPerListLead}`;
  const leadTitle = `${costPerLead}`;
  const costPerActiveUser = (budget / activeUsers).toFixed(0);
  const noActiveUsers = activeUsers === 0;
  const activeUsersTitle = noActiveUsers ? "N/A" : `$${costPerActiveUser}`;
  const today = new Date();
  const thirtyDays = 30 * 24 * 60 * 60 * 1000;
  const listEndDate = list?.endDate ?? list?.createdAt;
  const oneMonthPassed = today.getTime() - listEndDate.getTime() > thirtyDays;
  return <div data-sentry-component="KPIs" data-sentry-source-file="KPInsights.tsx">
      <h3 className="pt-6 text-xl font-semibold">KPIs</h3>
      <div className="pb-6 pt-2">
        KPIs are based on Secondary Insights which reflect the current status of
        your contributors&lsquo; engagement.
      </div>
      {oneMonthPassed && <div className="flex flex-wrap gap-6 ">
          <DashboardCard leadText="Direct leads" leads={listLeadTitle} metricName="Cost per list lead" tooltipText="Cost per lead generated during the list period" />
          <DashboardCard leadText="All leads" leads={leadTitle} metricName="Cost per lead" tooltipText="Cost per lead generated since the list start, including post-list leads" />
          <DashboardCard leadText="Leads & customers" leads={activeUsersTitle} metricName="Cost per active user" />
        </div>}
      {!oneMonthPassed && <>
          Allow a one-month period to elapse following the end date of the list
          before gathering additional insights.
        </>}
      <div className="pt-6">
        Setup a budget{" "}
        <button onClick={() => setSubMenu("Settings")} className="text-accent">
          here
        </button>{" "}
        and start{" "}
        <Link href={`/lists/${list.id}/dependency-tracking`} className="text-accent" data-sentry-element="Link" data-sentry-source-file="KPInsights.tsx">
          dependency tracking
        </Link>{" "}
        in order to calculate leads.
      </div>
    </div>;
};
export default KPIs;