import { api } from "~/store/trpc";

import useListParams from "./useListParams";

const useRemoveItemFromListList = (type: "repo" | "user") => {
  const utils = api.useUtils();
  const tableParams = useListParams();
  const filterOutContact = (contactId: string) => {
    utils.listContact.getContactsByListId.setInfiniteData(
      tableParams[type],
      (data) => {
        const newPages = data?.pages?.map((page) => {
          const newItems = page.items.filter((item) => {
            return item.id !== contactId;
          });
          return { ...page, items: newItems };
        });
        const result = {
          ...data,
          pages: newPages ?? [],
          cursor: null,
          pageParams: [null],
        };

        return result;
      }
    );
  };
  return filterOutContact;
};

export default useRemoveItemFromListList;
