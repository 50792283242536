import DashboardCard from "../../DashboardCard";
const SecondaryInsightsDashboards = ({
  userCount,
  leads,
  newLeads,
  customers
}: {
  userCount: number;
  leads: number;
  newLeads: number;
  customers: number;
}) => {
  const activeUsers = leads + customers + newLeads;
  return <div data-sentry-component="SecondaryInsightsDashboards" data-sentry-source-file="Dashboards.tsx">
      <div className="flex flex-wrap gap-6 ">
        <DashboardCard leadText="Active direct leads" percentage={(leads / userCount * 100).toFixed(2).toString() + "%"} metricName="Converted list leads" leads={leads.toString()} tooltipText="Leads generated during the list period who are still active" data-sentry-element="DashboardCard" data-sentry-source-file="Dashboards.tsx" />
        <DashboardCard leadText="Active follow-up leads" percentage={(newLeads / userCount * 100).toFixed(2).toString() + "%"} metricName="Converted post-list leads" leads={newLeads.toString()} tooltipText="Leads generated after the list ended who are still active" data-sentry-element="DashboardCard" data-sentry-source-file="Dashboards.tsx" />
        <DashboardCard leadText="Active returning customers" percentage={(customers / userCount * 100).toFixed(2).toString() + "%"} metricName="Active returning users" leads={customers.toString()} data-sentry-element="DashboardCard" data-sentry-source-file="Dashboards.tsx" />
        <DashboardCard leadText="All active leads & customers" percentage={(activeUsers / userCount * 100).toFixed(2).toString() + "%"} metricName="Total active users" leads={activeUsers.toString()} data-sentry-element="DashboardCard" data-sentry-source-file="Dashboards.tsx" />
      </div>
    </div>;
};
export default SecondaryInsightsDashboards;