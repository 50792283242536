import DashboardCard from "~/components/lists/MainPage/Dashboard/DashboardCard";
import { useList } from "~/providers/ListProvider";
const LightDashboard = ({
  leads,
  customers,
  repoCount,
  userCount
}: {
  leads: number;
  customers: number;
  repoCount: number;
  userCount: number;
  dependency: string;
}) => {
  const activeUsers = leads + customers;
  const list = useList();
  const {
    budget
  } = list;
  const costPerActiveUser = (budget / activeUsers).toFixed(0);
  const noActiveUsers = activeUsers === 0;
  const activeUsersTitle = noActiveUsers ? "N/A" : `$${costPerActiveUser}`;
  return <div data-sentry-component="LightDashboard" data-sentry-source-file="LightDashboard.tsx">
      {/* <div className="max-w-[720px]">
        <div className="flex  justify-between pt-2">
          <div className="flex items-center gap-4 font-medium">
            <div>Budget</div>
            <div className="h-min rounded-full bg-mauve-100 px-3 py-0.5 text-sm ">
              ${budget}
            </div>
          </div>
        </div>
       </div> */}

      <div className="flex flex-wrap gap-6 pt-7">
        <DashboardCard leadText="Evaluated Devs & Teams" percentage={repoCount?.toString()} metricName="Total Devs & Teams" leads={`${userCount}`} iconType="TwoPerson" data-sentry-element="DashboardCard" data-sentry-source-file="LightDashboard.tsx" />
        {userCount > 0 && <DashboardCard leadText="Current Active Devs" percentage={(activeUsers / userCount * 100).toFixed(2).toString() + "%"} metricName="Current Active Devs" leads={activeUsers.toString()} />}
        <DashboardCard leadText="Calculated Based on Budget" leads={activeUsersTitle} iconType="DollarSign" metricName="Cost Per Active Dev" data-sentry-element="DashboardCard" data-sentry-source-file="LightDashboard.tsx" />
      </div>
    </div>;
};
export default LightDashboard;