import React, { useEffect, useState } from "react";
import { useList } from "~/providers/ListProvider";
interface SelectDependencyProps {
  defaultDependencyName: string;
  onDependencyChange: (dependencyName: string) => void;
}
export default function SelectDependency({
  defaultDependencyName,
  onDependencyChange
}: SelectDependencyProps) {
  const list = useList();
  const [selectedDependency, setSelectedDependency] = useState(defaultDependencyName);
  function selectDependency(dependencyValue: string) {
    setSelectedDependency(dependencyValue);
    onDependencyChange(dependencyValue);
  }
  useEffect(() => {
    if (defaultDependencyName) {
      setSelectedDependency(defaultDependencyName);
    }
  }, [defaultDependencyName]);
  return <div className="flex items-center gap-2" data-sentry-component="SelectDependency" data-sentry-source-file="SelectDependency.tsx">
      <label htmlFor="dependency-select">Select Dependency:</label>
      <select id="dependency-select" value={selectedDependency} onChange={e => selectDependency(e.target.value)} className="rounded border border-gray-300 px-2 py-1">
        {list.trackers?.[0]?.dependencies.map(dep => <option key={dep} value={dep}>
            {dep}
          </option>)}
      </select>
    </div>;
}