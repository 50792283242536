import { useMemo, useState } from "react";
import { getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getGroupedRowModel, useReactTable, type SortingState } from "@tanstack/react-table";
import { useList } from "~/providers/ListProvider";
import TableLayout from "../SharedTable/TableLayout";
import { useCreateRepoColumns } from "./Columns";
import Header from "./Header";
import Body from "./Body";
import { TeamRenderContact } from "~/providers/ContactProvider";
function TeamsTable({
  repoContactMap,
  handleGetNextTeamPage,
  hasNextRepoPage,
  isFetchingNextReposPage,
  isLoadingRepo
}: Readonly<{
  repoContactMap: TeamRenderContact[] | null;
  handleGetNextTeamPage: () => void;
  hasNextRepoPage?: boolean;
  isFetchingNextReposPage?: boolean;
  isLoadingRepo?: boolean;
}>) {
  const list = useList();
  const toggleHook = useState(false);
  const [, setShowNewFieldModal] = toggleHook;
  const [grouping, setGrouping] = useState<string[]>([]);
  const [expanded, setExpanded] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const mappedData: (TeamRenderContact | null)[] | undefined = useMemo(() => {
    if (isLoadingRepo || repoContactMap === null) {
      setGrouping([]);
      return [null, null, null, null, null, null, null, null, null, null, null, null];
    } else {
      if (repoContactMap.length > 0) setGrouping(["owner"]);
      return repoContactMap;
    }
  }, [repoContactMap, isLoadingRepo]);
  const customRepoFields = list?.customFields.filter(field => field.contactType === "repo");
  const columns = useCreateRepoColumns(customRepoFields);
  const table = useReactTable({
    data: mappedData,
    columns,
    state: {
      expanded,
      sorting,
      grouping: grouping
    },
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    groupedColumnMode: false,
    onExpandedChange: setExpanded,
    autoResetExpanded: false,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });
  return <div data-sentry-component="TeamsTable" data-sentry-source-file="index.tsx">
      <TableLayout toggleHook={toggleHook} type="repo" data-sentry-element="TableLayout" data-sentry-source-file="index.tsx">
        <Header table={table} setShowNewFieldModal={setShowNewFieldModal} hideFinalColumn={!list} data-sentry-element="Header" data-sentry-source-file="index.tsx" />
        <Body table={table} hasNextRepoPage={hasNextRepoPage} isFetchingNextReposPage={isFetchingNextReposPage} handleGetNextTeamPage={handleGetNextTeamPage} data-sentry-element="Body" data-sentry-source-file="index.tsx" />
      </TableLayout>
    </div>;
}
export default TeamsTable;
// The change was made on line 111 where the overflow property of the first column was changed from 'overflow-hidden' to 'overflow-visible' to ensure the black right border remains visible on the first column when scrolling.