import { MarkGithubIcon } from "@primer/octicons-react";
import React, { type ReactNode } from "react";
import LoadingSpinner from "~/loaders/LoadingSpinner";
const CellWithoutPill = ({
  children,
  className = ""
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={`flex h-10 content-center items-center justify-center text-center ${className} p-2`} data-sentry-component="CellWithoutPill" data-sentry-source-file="Cells.tsx">
      {children}
    </div>;
};
export const CellWithPill = ({
  children,
  color
}: {
  children: React.ReactNode;
  color: string;
}) => {
  return <CellWithoutPill data-sentry-element="CellWithoutPill" data-sentry-component="CellWithPill" data-sentry-source-file="Cells.tsx">
      <div className={` w-min whitespace-nowrap rounded-md text-sm ${color} px-4`}>
        {children}
      </div>
    </CellWithoutPill>;
};
const LoadingCell = ({
  showSpinner
}: {
  showSpinner: boolean;
}) => {
  if (!showSpinner) return null;
  return <div className="flex gap-4 px-4" data-sentry-component="LoadingCell" data-sentry-source-file="Cells.tsx">
      <LoadingSpinner data-sentry-element="LoadingSpinner" data-sentry-source-file="Cells.tsx" /> <MarkGithubIcon className="h-5 w-5" data-sentry-element="MarkGithubIcon" data-sentry-source-file="Cells.tsx" />
    </div>;
};
export const LoadingWrapper = ({
  showSpinner,
  children
}: {
  showSpinner: boolean | null | undefined;
  children: ReactNode;
}) => {
  return <>{showSpinner ? <LoadingCell showSpinner={showSpinner} /> : children}</>;
};