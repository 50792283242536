import { UsersIcon } from "@heroicons/react/24/outline";
import { type ReactNode } from "react";
export default function Followers({
  children
}: Readonly<{
  children: ReactNode;
}>) {
  return <div className="flex content-center items-center gap-2 rounded-md border border-divider p-0.5 px-2" data-sentry-component="Followers" data-sentry-source-file="followers.tsx">
      <UsersIcon className="h-4 w-4" data-sentry-element="UsersIcon" data-sentry-source-file="followers.tsx" />
      Followers
      <div className="py-0.25  rounded-full bg-vlight px-1 text-xs">
        {children}
      </div>
    </div>;
}