import Link from "next/link";
import Card, { Body, Summary } from "~/components/layout/Card";
import CardScores from "~/components/score/Scores";
import Followers from "~/components/lists/MainPage/Cards/SharedCard/summary/followers";
import Stars from "~/components/lists/MainPage/Cards/SharedCard/summary/stars";
import UserCardHeader from "./UserCardHeader";
import CardMembers from "./Members";
import Tabs from "./tabs/Tabs";
import useRemoveItemFromListList from "../../SharedTable/hooks/useRemoveItemFromList";
import { TeamRenderContact } from "~/providers/ContactProvider";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
export default function UserCard({
  listContact
}: {
  listContact: NonNullable<TeamRenderContact>;
}) {
  const handleRemoveUserFromListList = useRemoveItemFromListList("user");
  const handleSetShowCard = () => {
    handleRemoveUserFromListList(listContact.id);
  };
  if (!listContact) return null;
  return <Card data-sentry-element="Card" data-sentry-component="UserCard" data-sentry-source-file="index.tsx">
      <UserCardHeader listContact={listContact} setShowCard={handleSetShowCard} data-sentry-element="UserCardHeader" data-sentry-source-file="index.tsx" />
      <Body data-sentry-element="Body" data-sentry-source-file="index.tsx">
        <Summary data-sentry-element="Summary" data-sentry-source-file="index.tsx">
          <div>
            <div className="line-clamp-3">
              {listContact.description ?? <span className="opacity-50">No bio available</span>}
            </div>
            {listContact && <Link href={`https://github.com/${listContact.githubName}`} className="text-accent flex gap-1 items-center" target="_blank">
                Read more
                <ArrowTopRightOnSquareIcon className="h-3 w-3 inline-block" />
              </Link>}
          </div>
          <div className="flex gap-4">
            <Stars data-sentry-element="Stars" data-sentry-source-file="index.tsx">{listContact.totalStars}</Stars>

            <Followers data-sentry-element="Followers" data-sentry-source-file="index.tsx">{listContact.followers}</Followers>
          </div>
        </Summary>
        <CardMembers listContact={listContact} data-sentry-element="CardMembers" data-sentry-source-file="index.tsx" />

        <CardScores listContact={listContact} contactIsLoading={false} data-sentry-element="CardScores" data-sentry-source-file="index.tsx" />
      </Body>
      <Tabs listContact={listContact} data-sentry-element="Tabs" data-sentry-source-file="index.tsx" />
    </Card>;
}