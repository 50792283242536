import { type Row, flexRender, type Cell } from "@tanstack/react-table";
import type { TeamRenderContact } from "~/providers/ContactProvider";
const getButtonActions = (row: Row<TeamRenderContact>) => {
  if (row.getLeafRows().length > 1) {
    return {
      onClick: row.getToggleExpandedHandler(),
      style: {
        cursor: row.getCanExpand() ? "pointer" : "normal"
      }
    };
  }
  return {};
};
export const getCellContent = (row: Row<TeamRenderContact>, cell: Cell<TeamRenderContact, unknown>) => {
  const buttonActions = getButtonActions(row);
  const hasMoreThanOneRow = row.getLeafRows().length > 1;
  if (cell.getIsGrouped()) {
    // If it's a grouped cell, add an expander and row count
    if (hasMoreThanOneRow) {
      return <button {...buttonActions}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </button>;
    } else return flexRender(cell.column.columnDef.cell, cell.getContext());
  }
  if (cell.getIsAggregated()) {
    // If the cell is aggregated, use the Aggregated
    // renderer for cell
    return flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext());
  }
  if (cell.getIsPlaceholder()) return null;
  return (
    // For cells with repeated values, render null
    // Otherwise, just render the regular cell
    flexRender(cell.column.columnDef.cell, cell.getContext())
  );
};