import DiscreetButton from "~/components/base/DiscreetButton";
import { Footer } from "~/components/layout/Card";
import { CardTabs } from "..";
import ChangesTab from "./Changes";
import DependenciesTab from "./Dependencies";
import DevelopersTab from "./Contributors";
import { decodeStatusAsObject } from "@openqlabs/utils";
import { TeamRenderContact } from "~/providers/ContactProvider";
import { ContactProvider } from "~/providers/ContactProvider";
import TabWrapper from "../../SharedCard/tabs/TabWrapper";
export default function Tabs({
  handleClickTab,
  showTab,
  listContact
}: Readonly<{
  handleClickTab: (tab: CardTabs) => void;
  showTab: CardTabs | null;
  listContact: NonNullable<TeamRenderContact>;
}>) {
  const dependencyStatus = decodeStatusAsObject(listContact.dependencyStatusNumber, listContact.manualStatus);
  return <Footer data-sentry-element="Footer" data-sentry-component="Tabs" data-sentry-source-file="Tabs.tsx">
      <div className="flex">
        <DiscreetButton className={`w-full !rounded-b-none !rounded-tl-none !border-none text-sm font-normal hover:bg-offwhite ${showTab === CardTabs.Changes ? "bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300 " : ""}`} onClick={() => handleClickTab(CardTabs.Changes)} data-sentry-element="DiscreetButton" data-sentry-source-file="Tabs.tsx">
          Changes
        </DiscreetButton>

        <DiscreetButton className={`w-full !rounded-b-none !border-none text-sm font-normal hover:!bg-offwhite ${showTab === CardTabs.Dependencies ? " bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300 " : ""}`} onClick={() => handleClickTab(CardTabs.Dependencies)} data-sentry-element="DiscreetButton" data-sentry-source-file="Tabs.tsx">
          Dependencies{" "}
          <span className={`mx-2 whitespace-nowrap rounded-md ${dependencyStatus.color} px-4 text-sm`}>
            {dependencyStatus.status}
          </span>
        </DiscreetButton>

        <DiscreetButton className={`w-full !rounded-b-none !rounded-tr-none !border-none text-sm font-normal hover:!bg-offwhite ${showTab === CardTabs.Developers ? "bg-offwhite dark:!bg-gray-900/50 dark:!text-gray-300" : ""}`} onClick={() => handleClickTab(CardTabs.Developers)} data-sentry-element="DiscreetButton" data-sentry-source-file="Tabs.tsx">
          Developers
        </DiscreetButton>
      </div>

      <div className={` overflow-auto bg-offwhite transition-all dark:bg-gray-900/50`}>
        {!!showTab && <ContactProvider id={listContact.id}>
            <TabWrapper type="repo">
              <>
                {CardTabs.Developers === showTab && <div className="p-6">
                    <DevelopersTab />
                  </div>}
                {CardTabs.Dependencies === showTab && <DependenciesTab />}
                {CardTabs.Changes === showTab && <ChangesTab />}
              </>
            </TabWrapper>
          </ContactProvider>}
      </div>
    </Footer>;
}