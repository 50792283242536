import { StarIcon } from "@primer/octicons-react";
import { type ReactNode } from "react";
export default function Stars({
  children
}: Readonly<{
  children: ReactNode;
}>) {
  return <div className="flex content-center items-center gap-2 rounded-md border border-divider p-0.5 px-2" data-sentry-component="Stars" data-sentry-source-file="stars.tsx">
      <StarIcon className="h-4 w-4" data-sentry-element="StarIcon" data-sentry-source-file="stars.tsx" />
      Stars
      <div className="py-0.25  rounded-full bg-vlight px-1 text-xs">
        {children}
      </div>
    </div>;
}