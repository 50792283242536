import { useVirtualizer } from "@tanstack/react-virtual";
import RepoCard from "./Cards/RepoCard";
import useFetchWhenDone from "~/hooks/useFetchWhenDone";
import { getScrollElement } from "~/components/lists/MainPage/SharedTable/utils";
import { TrackerProvider } from "~/providers/TrackerProvider";
import { useLists } from "~/providers/ListsProvider";
import { TeamRenderContact } from "~/providers/ContactProvider";
import useVirtualPadding from "~/hooks/useVirtualPadding";
const ListTeams = ({
  repoContactMap,
  handleGetNextTeamPage,
  hasNextRepoPage,
  isFetchingNextReposPage
}: {
  repoContactMap: TeamRenderContact[];
  handleGetNextTeamPage: () => void;
  hasNextRepoPage?: boolean;
  isFetchingNextReposPage?: boolean;
}) => {
  const rowCount: number = repoContactMap.length;
  const rowVirtualizer = useVirtualizer({
    getScrollElement,
    count: rowCount,
    estimateSize: () => 300,
    overscan: 20
  });
  const virtualRows = rowVirtualizer.getVirtualItems();
  useFetchWhenDone(handleGetNextTeamPage, virtualRows, rowCount, isFetchingNextReposPage, hasNextRepoPage);
  const [paddingTop, paddingBottom] = useVirtualPadding(rowVirtualizer);
  const {
    lists
  } = useLists();
  return <div id="table-container" className="xl grid h-[calc(100vh_-_270px)] w-full grid-cols-1 gap-y-6 overflow-scroll @8xl:grid-cols-2" data-sentry-component="ListTeams" data-sentry-source-file="ListTeams.tsx">
      {paddingTop > 0 && <tr>
          <td style={{
        height: `${paddingTop}px`
      }} />
        </tr>}
      {virtualRows.map(virtualRow => {
      const listContact = repoContactMap[virtualRow.index];
      const isLoaderRow = virtualRow.index > rowCount - 1;
      const isLastRow = isLoaderRow && !isFetchingNextReposPage;
      const list = lists.find(c => c.id === listContact?.listId);
      if (isLastRow) {
        return null;
      }
      return <TrackerProvider initialTracker={list?.trackers[0]}>
            <RepoCard listContact={listContact} />
          </TrackerProvider>;
    })}
      {paddingBottom > 0 && <tr>
          <td style={{
        height: `${paddingBottom}px`
      }} />
        </tr>}
    </div>;
};
export default ListTeams;